import React from "react";
import { Grid } from '@mui/material'
// import ComponentTopbar from "./ComponentTopbar";
import Exhibition from "./ComponentExhibition";
import ComponentButtonFilter from "./ComponentButtonFilter";

const Filter = () => {
  return (
    <Grid display="flex">
      <Grid sx={{ position: 'fixed', zIndex: "1000" }} >
        <ComponentButtonFilter />
      </Grid>

      {/* BOTAO: ALTERNAR ENTRE MAPA E LISTA */}
      <Grid>
        <Exhibition />
      </Grid>

    </Grid>
  );
};

export default Filter;