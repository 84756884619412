import {
    Box,
  } from '@mui/material';
  
  const commonBoxStyles = {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundColor: '#D0CDCE', // COR DE TODO O FUNDO
  };
  
  const Calculator = () => {
    return (
        <Box sx={commonBoxStyles}>
          calculadora
        </Box>
    );
  };
  
  export default Calculator;
  