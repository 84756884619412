import React from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const LoginForm = () => {
  const { loginWithRedirect, error } = useAuth0();

  const handleSubmit = (event) => {
    event.preventDefault();

    loginWithRedirect({
      authorizationParams: {
        organization: process.env.REACT_APP_AUTH0_ORGANIZATION
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="username"
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        required
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        required
      />
      {error && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {error.message}
        </Typography>
      )}
      <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
