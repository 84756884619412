import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { useAuth0 } from '@auth0/auth0-react';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [expanded, setExpanded] = useState(null);
  const { user } = useAuth0();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Item = ({ title, to, icon, selected, setSelected }) => (
    <Link to={to} style={{ textDecoration: "none" }}>
      <MenuItem
        active={selected === title}
        style={{
          margin: "5px",
          color: colors.grey[300],
          backgroundColor: "transparent !important",
          display: 'flex',
          justifyContent: isCollapsed ? 'center' : 'flex-start',
          borderRadius: '10px',
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Link>
  );

  return (
    <Box
      sx={{
        color: colors.grey[500],
        backgroundColor: "colors.blueAccent[600]",
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        margin: 0,
        padding: 0,
        position: 'relative',
      }}
    >
      <Sidebar 
        collapsed={isCollapsed} 
        style={{
          height: '100%',
          border: 'none',
        }}
        backgroundColor="transparent"
      >
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography 
                  variant="h3" 
                  color={colors.grey[100]}
                  sx={{ fontFamily: 'Kanit, sans-serif' }}
                >
                  aconchegou
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="2%">
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  sx={{ m: "0 0 0 0" }}
                >
                  {user ? user.nickname : 'Usuário Desconhecido'}
                </Typography>

                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>

              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Aprovações"
              to="/"
              icon={<DoneAllOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Box m="5px 5px 5px 5px">
              <Accordion defaultExpanded 
                expanded={expanded === 'panel1'}
                onChange={handleAccordionChange('panel1')}
                sx={{ 
                  justifyContent: 'center', 
                  alignItems: 'center '
                }}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    '& .MuiAccordionSummary-content': { margin: '0px', padding: '0px'}
                  }}
                >
                  <Typography>
                    <Box display="flex" justifyContent="center" width="100%">
                      <Box>
                        <Typography
                          variant="h6"
                          color={colors.grey[100]}
                        >
                          {isCollapsed ? "" : "Comercial"}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Item
                      title="Empreendimentos"
                      to="/empreendimentos"
                      icon={<MapsHomeWorkOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="Fornecedores"
                      to="/suppliers"
                      icon={<ContactsOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="Gerentes"
                      to="/managers"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box m="5px">
              <Accordion 
                expanded={expanded === 'panel2'}
                onChange={handleAccordionChange('panel2')}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    '& .MuiAccordionSummary-content': { margin: '0px', padding: '0px'}
                  }}
                >
                  <Typography>
                    <Box>
                      <Box>
                        <Typography
                          variant="h6"
                          color={colors.grey[100]}
                        >
                          {isCollapsed ? "" : "Vendas"}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Item
                    title="Agendamentos"
                    to="/schedules"
                    icon={<CalendarTodayOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Calculadora"
                    to="/calculadora"
                    icon={<CalculateOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Espelho de Vendas"
                    to="/salesmirror"
                    icon={<EqualizerOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Filtro"
                    to="/filtro"
                    icon={<FilterAltOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Materiais"
                    to="/materials"
                    icon={<AttachFileOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Negociações"
                    to="/negotiations"
                    icon={<MonetizationOnOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box m="5px">
              <Accordion 
                expanded={expanded === 'panel3'}
                onChange={handleAccordionChange('panel3')}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                    '& .MuiAccordionSummary-content': { margin: '0px', padding: '0px'}
                  }}
                >
                  <Typography>
                    <Box>
                      <Box>
                        <Typography
                          variant="h6"
                          color={colors.grey[100]}
                        >
                          {isCollapsed ? "" : "Atendimento"}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Item
                    title="Chat On-Line"
                    to="/chat"
                    icon={<ForumOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Mensagens"
                    to="/message"
                    icon={<CommentOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Avisos"
                    to="/warnings"
                    icon={<CampaignOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default CustomSidebar;
