import * as React from 'react';
import { Card, CardContent, Typography, Grid, styled, ButtonBase } from '@mui/material';
import PivotTableChartOutlinedIcon from '@mui/icons-material/PivotTableChartOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

const ImageContainer = styled('div')({
  overflow: 'hidden',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  opacity: 0.8,
  transition: 'transform 0.3s ease, opacity 0.3s ease',
  '&:hover': {
    opacity: 1,
    transform: 'scale(1.1)',
  }
});

const CustomCard = ({ metragem, quartos, vagas, entrega }) => {
  const handleCardClick = () => {
    // Defina aqui a ação que deve ocorrer quando o card for clicado
    alert('Card clicked!');
  };

  return (
    <Card sx={{ maxWidth: "300px", width: '100%', margin: '1%', borderRadius: '50px' }}>
      <ButtonBase
        sx={{ width: '100%', height: '100%', borderRadius: '50px' }}
        onClick={handleCardClick}
      >
        <CardContent sx={{ backgroundColor: "#F0F0F0", padding: 0, width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImageContainer>
                <StyledImage 
                  src="https://aconchegou.com/wp-content/uploads/2023/11/fachada-2-e1699206035332.jpg" 
                  alt="Fachada"
                  sx={{ objectFit: 'cover' }}
                />
              </ImageContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography 
                variant="body1" 
                align="left" 
                padding="0 0 0 5%"
                color="#033649"
                fontSize= "100%"
                fontWeight="bold"
              >
                Construtora
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography 
                variant="body1" 
                align="right" 
                padding="0 5% 0 0"
                color="#033649"
                fontSize= "100%"
                fontWeight="bold"
              >
                A partir de
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography 
                variant="body1" 
                align="left" 
                padding="0 0 0 5%"
                color="#73A644"
                fontSize= "120%"
                fontWeight="bold"
              >
                Nome do Condomínio
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography 
                variant="body1" 
                align="right" 
                padding="0 5% 0 0"
                color="#73A644"
                fontSize= "120%"
                fontWeight="bold"
              >
                R$ 500,000.00
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* Espaço em branco */}
            </Grid>
            <Grid item xs={6}>
              <Typography 
                variant="body1" 
                padding="0 5% 0 0" 
                align="right"
                color="#033649"
                fontSize= "100%"
                fontWeight="bold"
                flexWrap= 'nowrap'
              >
                Bairro do Imóvel, SP
              </Typography>
            </Grid>
            <Grid 
              container 
              spacing={2} 
              sx={{ 
                padding: "0", 
                margin: "15px 0px 15px 15px",
                flexWrap: 'nowrap', 
                overflowX: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid 
                item 
                xs={3}
                sx={{ 
                  margin: "0 2.5px 0px 5px",
                  padding: "10px",
                  borderRadius: "10px",
                  background: "#F0F0F0", 
                  color: "#033649", 
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  Width: '100%',
                }}
              >
                <PivotTableChartOutlinedIcon sx={{ fontSize: '100%' }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "100%", flexWrap: 'nowrap' }}>60m²</Typography>
              </Grid>
              <Grid 
                item 
                xs={3}
                sx={{ 
                  margin: "0 2.5px 0px 2.5px",
                  padding: "10px",
                  borderRadius: "10px",
                  background: "#F0F0F0", 
                  color: "#033649", 
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  Width: '100%',
                }}
              >
                <BedOutlinedIcon sx={{ fontSize: '100%' }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "100%", flexWrap: 'nowrap' }}>2 ou 3</Typography>
              </Grid>
              <Grid 
                item 
                xs={3}
                sx={{ 
                  margin: "0 2.5px 0px 2.5px",
                  padding: "10px",
                  borderRadius: "10px",
                  background: "#F0F0F0", 
                  color: "#033649", 
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  Width: '100%',
                }}
              >
                <DirectionsCarFilledOutlinedIcon sx={{ fontSize: '100%' }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "100%", flexWrap: 'nowrap' }}>1 ou 2</Typography>
              </Grid>
              <Grid 
                item 
                xs={3}
                sx={{ 
                  margin: "0 5px 0px 2.5px",
                  padding: "10px",
                  borderRadius: "10px",
                  background: "#F0F0F0", 
                  color: "#033649",
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  Width: '100%',
                }}
              >
                <EventOutlinedIcon sx={{ fontWeight: "bold", fontSize: '100%' }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "100%", flexWrap: 'nowrap' }}>Jan/25</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>
    </Card>
  );
}

export default CustomCard;
