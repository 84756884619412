import React from 'react';
import { styled, alpha, Box, TextField } from '@mui/material';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';

export default function RangeSlider() {
  const [value, setValue] = React.useState([20000, 37000]); // Valores iniciais em reais
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(1000000);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMinChange = (event) => {
    const newMin = Number(event.target.value.replace(/\D/g, ''));
    if (newMin <= value[1] && newMin >= 0) {
      setMinValue(newMin);
      setValue([Math.max(newMin, value[0]), value[1]]);
    }
  };

  const handleMaxChange = (event) => {
    const newMax = Number(event.target.value.replace(/\D/g, ''));
    if (newMax >= value[0] && newMax <= 1000000) {
      setMaxValue(newMax);
      setValue([value[0], Math.min(newMax, value[1])]);
    }
  };

  const formatCurrency = (value) => {
    return `R$ ${value.toLocaleString('pt-BR')}`;
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', p: 2 }}>
      {/* Slider */}
      <Slider
        value={value}
        onChange={handleSliderChange}
        min={minValue}
        max={maxValue}
        valueLabelDisplay="auto"
        valueLabelFormat={formatCurrency}
        getAriaLabel={() => 'Faixa de preço'}
        getAriaValueText={valuetext}
      />
      
      {/* Min and Max Inputs */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <TextField
          label="Min (R$)"
          type="text"
          value={formatCurrency(minValue)}
          onChange={handleMinChange}
          InputProps={{
            inputProps: { min: 0, max: maxValue }
          }}
          sx={{
            backgroundColor: '#d0d0d0', // Cor de fundo mais escura
            color: '#fff', // Cor do texto
            '& .MuiInputLabel-root': {
              color: '#fff', // Cor do rótulo
            },
            '& .MuiInputBase-input': {
              color: '#fff', // Cor do texto do campo
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#6B7A90', // Cor da borda
              },
              '&:hover fieldset': {
                borderColor: '#007FFF', // Cor da borda ao passar o mouse
              },
              '&.Mui-focused fieldset': {
                borderColor: '#007FFF', // Cor da borda ao focar
              },
            },
          }}
        />
        <TextField
          label="Max (R$)"
          type="text"
          value={formatCurrency(maxValue)}
          onChange={handleMaxChange}
          InputProps={{
            inputProps: { min: minValue, max: 1000000 }
          }}
          sx={{
            backgroundColor: '#d0d0d0', // Cor de fundo mais escura
            color: '#fff', // Cor do texto
            '& .MuiInputLabel-root': {
              color: '#fff', // Cor do rótulo
            },
            '& .MuiInputBase-input': {
              color: '#fff', // Cor do texto do campo
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#6B7A90', // Cor da borda
              },
              '&:hover fieldset': {
                borderColor: '#007FFF', // Cor da borda ao passar o mouse
              },
              '&.Mui-focused fieldset': {
                borderColor: '#007FFF', // Cor da borda ao focar
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}

function valuetext(value) {
  return `R$ ${value.toLocaleString('pt-BR')}`;
}

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  300: '#66B2FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B3',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Slider = styled(BaseSlider)(
  ({ theme }) => `
  color: ${theme.palette.mode === 'light' ? blue[500] : blue[400]};
  height: 6px;
  width: 100%; /* Ajusta o slider para preencher o container */
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${theme.palette.mode === 'light' ? grey[300] : grey[600]};
    opacity: 0.4;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: ${theme.palette.mode === 'light' ? blue[500] : blue[400]};
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &:hover {
      box-shadow: 0 0 0 6px ${alpha(
        theme.palette.mode === 'light' ? blue[200] : blue[300],
        0.3,
      )};
    }

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 8px ${alpha(
        theme.palette.mode === 'light' ? blue[200] : blue[400],
        0.5,
      )};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 8px ${alpha(
        theme.palette.mode === 'light' ? blue[200] : blue[400],
        0.5,
      )};
      outline: none;
      transform: scale(1.2);
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 99%;
    background-color: ${theme.palette.mode === 'light' ? blue[200] : blue[900]};
    top: 44%;
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: ${theme.palette.mode === 'light' ? blue[500] : blue[400]};
  }
`,
);
