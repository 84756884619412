import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataSuppliers = [
  {
    id: 1,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor A",
    razao_social: "Fornecedor A LTDA",
    cnpj: "12.345.678/0001-01",
    telefone: "(11) 98765-4321",
    email: "fornecedor.a@exemplo.com",
  },
  {
    id: 2,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor B",
    razao_social: "Fornecedor B LTDA",
    cnpj: "23.456.789/0001-02",
    telefone: "(21) 97654-3210",
    email: "fornecedor.b@exemplo.com",
  },
  {
    id: 3,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor C",
    razao_social: "Fornecedor C LTDA",
    cnpj: "34.567.890/0001-03",
    telefone: "(31) 96543-2109",
    email: "fornecedor.c@exemplo.com",
  },
  {
    id: 4,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor D",
    razao_social: "Fornecedor D LTDA",
    cnpj: "45.678.901/0001-04",
    telefone: "(41) 95432-1098",
    email: "fornecedor.d@exemplo.com",
  },
  {
    id: 5,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor E",
    razao_social: "Fornecedor E LTDA",
    cnpj: "56.789.012/0001-05",
    telefone: "(51) 94321-0987",
    email: "fornecedor.e@exemplo.com",
  },
  {
    id: 6,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor F",
    razao_social: "Fornecedor F LTDA",
    cnpj: "67.890.123/0001-06",
    telefone: "(61) 93210-9876",
    email: "fornecedor.f@exemplo.com",
  },
  {
    id: 7,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor G",
    razao_social: "Fornecedor G LTDA",
    cnpj: "78.901.234/0001-07",
    telefone: "(71) 92109-8765",
    email: "fornecedor.g@exemplo.com",
  },
  {
    id: 8,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor H",
    razao_social: "Fornecedor H LTDA",
    cnpj: "89.012.345/0001-08",
    telefone: "(81) 91098-7654",
    email: "fornecedor.h@exemplo.com",
  },
  {
    id: 9,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor I",
    razao_social: "Fornecedor I LTDA",
    cnpj: "90.123.456/0001-09",
    telefone: "(91) 90987-6543",
    email: "fornecedor.i@exemplo.com",
  },
  {
    id: 10,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor J",
    razao_social: "Fornecedor J LTDA",
    cnpj: "01.234.567/0001-10",
    telefone: "(11) 99876-5432",
    email: "fornecedor.j@exemplo.com",
  },
  {
    id: 11,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor K",
    razao_social: "Fornecedor K LTDA",
    cnpj: "12.345.678/0001-11",
    telefone: "(21) 98765-4321",
    email: "fornecedor.k@exemplo.com",
  },
  {
    id: 12,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor L",
    razao_social: "Fornecedor L LTDA",
    cnpj: "23.456.789/0001-12",
    telefone: "(31) 97654-3210",
    email: "fornecedor.l@exemplo.com",
  },
  {
    id: 13,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor M",
    razao_social: "Fornecedor M LTDA",
    cnpj: "34.567.890/0001-13",
    telefone: "(41) 96543-2109",
    email: "fornecedor.m@exemplo.com",
  },
  {
    id: 14,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor N",
    razao_social: "Fornecedor N LTDA",
    cnpj: "45.678.901/0001-14",
    telefone: "(51) 95432-1098",
    email: "fornecedor.n@exemplo.com",
  },
  {
    id: 15,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor O",
    razao_social: "Fornecedor O LTDA",
    cnpj: "56.789.012/0001-15",
    telefone: "(61) 94321-0987",
    email: "fornecedor.o@exemplo.com",
  },
  {
    id: 16,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor P",
    razao_social: "Fornecedor P LTDA",
    cnpj: "67.890.123/0001-16",
    telefone: "(71) 93210-9876",
    email: "fornecedor.p@exemplo.com",
  },
  {
    id: 17,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor Q",
    razao_social: "Fornecedor Q LTDA",
    cnpj: "78.901.234/0001-17",
    telefone: "(81) 92109-8765",
    email: "fornecedor.q@exemplo.com",
  },
  {
    id: 18,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor R",
    razao_social: "Fornecedor R LTDA",
    cnpj: "89.012.345/0001-18",
    telefone: "(91) 91098-7654",
    email: "fornecedor.r@exemplo.com",
  },
  {
    id: 19,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor S",
    razao_social: "Fornecedor S LTDA",
    cnpj: "90.123.456/0001-19",
    telefone: "(11) 90987-6543",
    email: "fornecedor.s@exemplo.com",
  },
  {
    id: 20,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor T",
    razao_social: "Fornecedor T LTDA",
    cnpj: "01.234.567/0001-20",
    telefone: "(21) 99876-5432",
    email: "fornecedor.t@exemplo.com",
  },
  {
    id: 21,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor U",
    razao_social: "Fornecedor U LTDA",
    cnpj: "12.345.678/0001-21",
    telefone: "(31) 98765-4321",
    email: "fornecedor.u@exemplo.com",
  },
  {
    id: 22,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor V",
    razao_social: "Fornecedor V LTDA",
    cnpj: "23.456.789/0001-22",
    telefone: "(41) 97654-3210",
    email: "fornecedor.v@exemplo.com",
  },
  {
    id: 23,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor W",
    razao_social: "Fornecedor W LTDA",
    cnpj: "34.567.890/0001-23",
    telefone: "(51) 96543-2109",
    email: "fornecedor.w@exemplo.com",
  },
  {
    id: 24,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor X",
    razao_social: "Fornecedor X LTDA",
    cnpj: "45.678.901/0001-24",
    telefone: "(61) 95432-1098",
    email: "fornecedor.x@exemplo.com",
  },
  {
    id: 25,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor Y",
    razao_social: "Fornecedor Y LTDA",
    cnpj: "56.789.012/0001-25",
    telefone: "(71) 94321-0987",
    email: "fornecedor.y@exemplo.com",
  },
  {
    id: 26,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor Z",
    razao_social: "Fornecedor Z LTDA",
    cnpj: "67.890.123/0001-26",
    telefone: "(81) 93210-9876",
    email: "fornecedor.z@exemplo.com",
  },
  {
    id: 27,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor AA",
    razao_social: "Fornecedor AA LTDA",
    cnpj: "78.901.234/0001-27",
    telefone: "(91) 92109-8765",
    email: "fornecedor.aa@exemplo.com",
  },
  {
    id: 28,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor BB",
    razao_social: "Fornecedor BB LTDA",
    cnpj: "89.012.345/0001-28",
    telefone: "(11) 91098-7654",
    email: "fornecedor.bb@exemplo.com",
  },
  {
    id: 29,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor CC",
    razao_social: "Fornecedor CC LTDA",
    cnpj: "90.123.456/0001-29",
    telefone: "(21) 90987-6543",
    email: "fornecedor.cc@exemplo.com",
  },
  {
    id: 30,
    logo: "https://www.serviermalaysia.com/wp-content/uploads/2022/08/image-default-icon.png",
    tipo: "Fornecedor",
    name: "Fornecedor DD",
    razao_social: "Fornecedor DD LTDA",
    cnpj: "01.234.567/0001-30",
    telefone: "(31) 99876-5432",
    email: "fornecedor.dd@exemplo.com",
  },
];

export const mockDataImoveis = [
  {
    id: 1,
    nome: "Residencial Aurora",
    lancamento: "2022-03-10T00:00:00Z",
    entrega: "2024-08-15T00:00:00Z",
    construtora: "Construtora ABC",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 2,
    nome: "Edifício Sol Nascente",
    lancamento: "2021-12-05T00:00:00Z",
    entrega: "2023-05-20T00:00:00Z",
    construtora: "Construtora XYZ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 3,
    nome: "Vila dos Pássaros",
    lancamento: "2020-07-22T00:00:00Z",
    entrega: "2022-10-30T00:00:00Z",
    construtora: "Construtora QWE",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 4,
    nome: "Condomínio Flores",
    lancamento: "2023-01-18T00:00:00Z",
    entrega: "2025-12-25T00:00:00Z",
    construtora: "Construtora RTY",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 5,
    nome: "Residencial Mar Azul",
    lancamento: "2021-06-11T00:00:00Z",
    entrega: "2023-11-17T00:00:00Z",
    construtora: "Construtora UVW",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 6,
    nome: "Edifício Vento Norte",
    lancamento: "2022-09-09T00:00:00Z",
    entrega: "2024-04-19T00:00:00Z",
    construtora: "Construtora STU",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 7,
    nome: "Vila dos Ventos",
    lancamento: "2021-02-14T00:00:00Z",
    entrega: "2023-09-18T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 8,
    nome: "Condomínio Jardim",
    lancamento: "2022-05-07T00:00:00Z",
    entrega: "2024-11-11T00:00:00Z",
    construtora: "Construtora LMN",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 9,
    nome: "Residencial Terra Nova",
    lancamento: "2021-08-20T00:00:00Z",
    entrega: "2024-03-25T00:00:00Z",
    construtora: "Construtora IJK",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 10,
    nome: "Edifício Água Viva",
    lancamento: "2020-11-13T00:00:00Z",
    entrega: "2023-06-30T00:00:00Z",
    construtora: "Construtora DEF",
    publicado: false,
    status: "Reprovado",
  },
  {
    id: 11,
    nome: "Vila do Sol",
    lancamento: "2022-04-01T00:00:00Z",
    entrega: "2024-12-20T00:00:00Z",
    construtora: "Construtora GHI",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 12,
    nome: "Residencial Céu Azul",
    lancamento: "2021-07-15T00:00:00Z",
    entrega: "2023-11-10T00:00:00Z",
    construtora: "Construtora JKL",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 13,
    nome: "Edifício Verano",
    lancamento: "2020-09-12T00:00:00Z",
    entrega: "2023-04-18T00:00:00Z",
    construtora: "Construtora MNO",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 14,
    nome: "Condomínio Primavera",
    lancamento: "2023-03-25T00:00:00Z",
    entrega: "2026-05-30T00:00:00Z",
    construtora: "Construtora PQR",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 15,
    nome: "Residencial Pôr do Sol",
    lancamento: "2021-08-05T00:00:00Z",
    entrega: "2024-02-14T00:00:00Z",
    construtora: "Construtora STU",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 16,
    nome: "Edifício Horizonte",
    lancamento: "2022-06-01T00:00:00Z",
    entrega: "2024-09-15T00:00:00Z",
    construtora: "Construtora VWX",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 17,
    nome: "Vila das Flores",
    lancamento: "2021-01-20T00:00:00Z",
    entrega: "2023-08-25T00:00:00Z",
    construtora: "Construtora YZA",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 18,
    nome: "Condomínio Santa Maria",
    lancamento: "2022-10-10T00:00:00Z",
    entrega: "2024-12-10T00:00:00Z",
    construtora: "Construtora BCD",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 19,
    nome: "Residencial Novo Horizonte",
    lancamento: "2021-05-22T00:00:00Z",
    entrega: "2023-09-19T00:00:00Z",
    construtora: "Construtora EFG",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 20,
    nome: "Edifício Vila Real",
    lancamento: "2020-11-25T00:00:00Z",
    entrega: "2023-07-30T00:00:00Z",
    construtora: "Construtora HIJ",
    publicado: false,
    status: "Reprovado",
  },
  {
    id: 21,
    nome: "Residencial Parque das Águas",
    lancamento: "2022-01-12T00:00:00Z",
    entrega: "2024-03-15T00:00:00Z",
    construtora: "Construtora KLM",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 22,
    nome: "Condomínio Lago Azul",
    lancamento: "2021-09-18T00:00:00Z",
    entrega: "2024-01-28T00:00:00Z",
    construtora: "Construtora NOP",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 23,
    nome: "Edifício Maravilha",
    lancamento: "2020-06-05T00:00:00Z",
    entrega: "2023-10-12T00:00:00Z",
    construtora: "Construtora QRS",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 24,
    nome: "Vila Bela Vista",
    lancamento: "2023-02-20T00:00:00Z",
    entrega: "2026-04-25T00:00:00Z",
    construtora: "Construtora TUV",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 25,
    nome: "Residencial Campo Verde",
    lancamento: "2021-03-10T00:00:00Z",
    entrega: "2024-05-30T00:00:00Z",
    construtora: "Construtora WXY",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 26,
    nome: "Condomínio Vista Alegre",
    lancamento: "2022-08-15T00:00:00Z",
    entrega: "2024-10-12T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 27,
    nome: "Edifício Serenidade",
    lancamento: "2021-10-05T00:00:00Z",
    entrega: "2023-12-20T00:00:00Z",
    construtora: "Construtora CDE",
    publicado: false,
    status: "Reprovado",
  },
  {
    id: 28,
    nome: "Vila das Acácias",
    lancamento: "2022-12-01T00:00:00Z",
    entrega: "2025-03-15T00:00:00Z",
    construtora: "Construtora FGH",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 29,
    nome: "Residencial Jardim das Orquídeas",
    lancamento: "2021-04-20T00:00:00Z",
    entrega: "2023-08-10T00:00:00Z",
    construtora: "Construtora IJK",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 30,
    nome: "Edifício Estrela do Mar",
    lancamento: "2020-07-10T00:00:00Z",
    entrega: "2023-09-25T00:00:00Z",
    construtora: "Construtora LMN",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 31,
    nome: "Condomínio Ponta do Sol",
    lancamento: "2022-03-25T00:00:00Z",
    entrega: "2025-01-15T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 32,
    nome: "Residencial Bela Vista",
    lancamento: "2021-12-10T00:00:00Z",
    entrega: "2024-04-05T00:00:00Z",
    construtora: "Construtora RST",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 33,
    nome: "Edifício Terra Nova",
    lancamento: "2020-05-30T00:00:00Z",
    entrega: "2023-11-20T00:00:00Z",
    construtora: "Construtora UVW",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 34,
    nome: "Vila dos Jardins",
    lancamento: "2023-04-01T00:00:00Z",
    entrega: "2026-07-25T00:00:00Z",
    construtora: "Construtora XYZ",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 35,
    nome: "Residencial Alto da Serra",
    lancamento: "2021-09-05T00:00:00Z",
    entrega: "2024-03-20T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 36,
    nome: "Condomínio Nova Era",
    lancamento: "2022-07-10T00:00:00Z",
    entrega: "2025-01-05T00:00:00Z",
    construtora: "Construtora CDE",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 37,
    nome: "Edifício Solar",
    lancamento: "2021-11-25T00:00:00Z",
    entrega: "2024-06-15T00:00:00Z",
    construtora: "Construtora EFG",
    publicado: false,
    status: "Reprovado",
  },
  {
    id: 38,
    nome: "Vila dos Cedros",
    lancamento: "2022-02-15T00:00:00Z",
    entrega: "2024-08-30T00:00:00Z",
    construtora: "Construtora GHI",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 39,
    nome: "Residencial Vale Verde",
    lancamento: "2021-06-25T00:00:00Z",
    entrega: "2023-12-01T00:00:00Z",
    construtora: "Construtora HIJ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 40,
    nome: "Edifício Oceano Azul",
    lancamento: "2020-08-20T00:00:00Z",
    entrega: "2023-05-15T00:00:00Z",
    construtora: "Construtora JKL",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 41,
    nome: "Condomínio Jardim das Flores",
    lancamento: "2022-05-25T00:00:00Z",
    entrega: "2024-11-01T00:00:00Z",
    construtora: "Construtora KLM",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 42,
    nome: "Residencial Terraço do Sol",
    lancamento: "2021-02-10T00:00:00Z",
    entrega: "2023-09-10T00:00:00Z",
    construtora: "Construtora LMN",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 43,
    nome: "Edifício Vila Bella",
    lancamento: "2020-12-01T00:00:00Z",
    entrega: "2023-03-20T00:00:00Z",
    construtora: "Construtora MNO",
    publicado: false,
    status: "Reprovado",
  },
  {
    id: 44,
    nome: "Condomínio Alvorada",
    lancamento: "2022-04-15T00:00:00Z",
    entrega: "2025-02-15T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 45,
    nome: "Residencial Costa do Sol",
    lancamento: "2021-07-01T00:00:00Z",
    entrega: "2023-11-01T00:00:00Z",
    construtora: "Construtora PQR",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 46,
    nome: "Edifício Jardim do Mar",
    lancamento: "2020-10-05T00:00:00Z",
    entrega: "2023-08-15T00:00:00Z",
    construtora: "Construtora STU",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 47,
    nome: "Vila Verde",
    lancamento: "2022-06-10T00:00:00Z",
    entrega: "2024-09-30T00:00:00Z",
    construtora: "Construtora VWX",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 48,
    nome: "Residencial Terra dos Sonhos",
    lancamento: "2021-04-25T00:00:00Z",
    entrega: "2023-12-10T00:00:00Z",
    construtora: "Construtora YZA",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 49,
    nome: "Edifício Lago dos Cisnes",
    lancamento: "2020-05-15T00:00:00Z",
    entrega: "2023-10-01T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 50,
    nome: "Condomínio Vale do Sol",
    lancamento: "2022-01-05T00:00:00Z",
    entrega: "2024-04-10T00:00:00Z",
    construtora: "Construtora CDE",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 51,
    nome: "Residencial Primavera",
    lancamento: "2021-12-20T00:00:00Z",
    entrega: "2024-07-10T00:00:00Z",
    construtora: "Construtora FGH",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 52,
    nome: "Edifício Horizonte",
    lancamento: "2020-06-15T00:00:00Z",
    entrega: "2023-05-01T00:00:00Z",
    construtora: "Construtora HIJ",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 53,
    nome: "Condomínio Porto Real",
    lancamento: "2022-09-01T00:00:00Z",
    entrega: "2024-12-20T00:00:00Z",
    construtora: "Construtora KLM",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 54,
    nome: "Residencial Vale dos Eucaliptos",
    lancamento: "2021-11-10T00:00:00Z",
    entrega: "2023-09-30T00:00:00Z",
    construtora: "Construtora MNO",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 55,
    nome: "Edifício Costa Verde",
    lancamento: "2020-02-20T00:00:00Z",
    entrega: "2023-07-15T00:00:00Z",
    construtora: "Construtora PQR",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 56,
    nome: "Vila das Palmeiras",
    lancamento: "2022-03-05T00:00:00Z",
    entrega: "2024-06-25T00:00:00Z",
    construtora: "Construtora STU",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 57,
    nome: "Residencial Jardim das Palmeiras",
    lancamento: "2021-10-15T00:00:00Z",
    entrega: "2024-02-15T00:00:00Z",
    construtora: "Construtora UVW",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 58,
    nome: "Edifício Horizonte Azul",
    lancamento: "2020-04-10T00:00:00Z",
    entrega: "2023-01-10T00:00:00Z",
    construtora: "Construtora XYZ",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 59,
    nome: "Condomínio Solar do Leste",
    lancamento: "2022-10-20T00:00:00Z",
    entrega: "2025-03-30T00:00:00Z",
    construtora: "Construtora YZA",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 60,
    nome: "Residencial Jardim dos Bosques",
    lancamento: "2021-05-10T00:00:00Z",
    entrega: "2023-07-25T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 61,
    nome: "Edifício Porto Belo",
    lancamento: "2020-11-20T00:00:00Z",
    entrega: "2023-08-05T00:00:00Z",
    construtora: "Construtora CDE",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 62,
    nome: "Vila dos Lagos",
    lancamento: "2022-01-25T00:00:00Z",
    entrega: "2024-11-20T00:00:00Z",
    construtora: "Construtora FGH",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 63,
    nome: "Residencial Águas Claras",
    lancamento: "2021-03-10T00:00:00Z",
    entrega: "2023-12-01T00:00:00Z",
    construtora: "Construtora HIJ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 64,
    nome: "Edifício Jardim do Sol",
    lancamento: "2020-09-05T00:00:00Z",
    entrega: "2023-04-25T00:00:00Z",
    construtora: "Construtora KLM",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 65,
    nome: "Condomínio Estrela do Norte",
    lancamento: "2022-11-15T00:00:00Z",
    entrega: "2025-02-01T00:00:00Z",
    construtora: "Construtora MNO",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 66,
    nome: "Residencial Vale dos Pinos",
    lancamento: "2021-06-01T00:00:00Z",
    entrega: "2023-10-10T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 67,
    nome: "Edifício Céu Azul",
    lancamento: "2020-12-15T00:00:00Z",
    entrega: "2023-09-20T00:00:00Z",
    construtora: "Construtora PQP",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 68,
    nome: "Condomínio Verde e Mar",
    lancamento: "2022-02-20T00:00:00Z",
    entrega: "2025-01-20T00:00:00Z",
    construtora: "Construtora RST",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 69,
    nome: "Residencial Alto da Colina",
    lancamento: "2021-07-15T00:00:00Z",
    entrega: "2023-10-25T00:00:00Z",
    construtora: "Construtora STU",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 70,
    nome: "Edifício Porto Real",
    lancamento: "2020-03-10T00:00:00Z",
    entrega: "2023-02-15T00:00:00Z",
    construtora: "Construtora UVW",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 71,
    nome: "Condomínio Jardim dos Pássaros",
    lancamento: "2022-07-20T00:00:00Z",
    entrega: "2025-04-15T00:00:00Z",
    construtora: "Construtora YZA",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 72,
    nome: "Residencial Alto do Vale",
    lancamento: "2021-04-15T00:00:00Z",
    entrega: "2023-12-15T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 73,
    nome: "Edifício Morada do Sol",
    lancamento: "2020-06-10T00:00:00Z",
    entrega: "2023-01-05T00:00:00Z",
    construtora: "Construtora ABC",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 74,
    nome: "Condomínio Litoral Sul",
    lancamento: "2022-08-25T00:00:00Z",
    entrega: "2024-12-01T00:00:00Z",
    construtora: "Construtora DEF",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 75,
    nome: "Residencial Jardim do Vale",
    lancamento: "2021-11-05T00:00:00Z",
    entrega: "2023-10-20T00:00:00Z",
    construtora: "Construtora GHI",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 76,
    nome: "Edifício Costa Mar",
    lancamento: "2020-04-25T00:00:00Z",
    entrega: "2023-06-10T00:00:00Z",
    construtora: "Construtora HIJ",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 77,
    nome: "Condomínio Alto das Árvores",
    lancamento: "2022-12-15T00:00:00Z",
    entrega: "2025-06-01T00:00:00Z",
    construtora: "Construtora JKL",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 78,
    nome: "Residencial Vila dos Campos",
    lancamento: "2021-05-15T00:00:00Z",
    entrega: "2023-07-15T00:00:00Z",
    construtora: "Construtora MNO",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 79,
    nome: "Edifício Jardim do Leste",
    lancamento: "2020-11-15T00:00:00Z",
    entrega: "2023-04-10T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: true,
    status: "Reprovado",
  },
  {
  id: 80,
  nome: "Condomínio Porto Verde",
  lancamento: "2022-10-10T00:00:00Z",
  entrega: "2025-01-10T00:00:00Z",
  construtora: "Construtora QRS",
  publicado: true,
  status: "Aprovado",
  },
  {
  id: 81,
  nome: "Residencial Bosque dos Eucaliptos",
  lancamento: "2021-08-05T00:00:00Z",
  entrega: "2023-11-15T00:00:00Z",
  construtora: "Construtora RST",
  publicado: false,
  status: "Rascunho",
  },
  {
  id: 82,
  nome: "Edifício Vale dos Pinhais",
  lancamento: "2020-03-15T00:00:00Z",
  entrega: "2023-07-25T00:00:00Z",
  construtora: "Construtora STU",
  publicado: true,
  status: "Reprovado",
  },
  {
  id: 83,
  nome: "Condomínio Vale das Palmeiras",
  lancamento: "2022-04-10T00:00:00Z",
  entrega: "2025-05-15T00:00:00Z",
  construtora: "Construtora UVW",
  publicado: true,
  status: "Aprovado",
  },
  {
  id: 84,
  nome: "Residencial Jardim dos Cedros",
    lancamento: "2021-12-15T00:00:00Z",
    entrega: "2024-06-01T00:00:00Z",
    construtora: "Construtora XYZ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 85,
    nome: "Edifício Porto das Águas",
    lancamento: "2020-09-20T00:00:00Z",
    entrega: "2023-06-05T00:00:00Z",
    construtora: "Construtora YZA",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 86,
    nome: "Condomínio Estância do Sul",
    lancamento: "2022-05-15T00:00:00Z",
    entrega: "2025-03-05T00:00:00Z",
    construtora: "Construtora ZAB",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 87,
    nome: "Residencial Jardim das Águas",
    lancamento: "2021-09-10T00:00:00Z",
    entrega: "2023-10-05T00:00:00Z",
    construtora: "Construtora CDE",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 88,
    nome: "Edifício Costa do Atlântico",
    lancamento: "2020-07-15T00:00:00Z",
    entrega: "2023-11-05T00:00:00Z",
    construtora: "Construtora DEF",
    publicado: true,
    status: "Reprovado",
  },
  {
  id: 89,
  nome: "Condomínio Sol Nascente",
  lancamento: "2022-11-01T00:00:00Z",
  entrega: "2025-05-30T00:00:00Z",
  construtora: "Construtora EFG",
  publicado: true,
  status: "Aprovado",
  },
  {
  id: 90,
  nome: "Residencial Vale do Mar",
  lancamento: "2021-08-25T00:00:00Z",
  entrega: "2023-12-01T00:00:00Z",
  construtora: "Construtora GHI",
  publicado: false,
  status: "Rascunho",
  },
  {
  id: 91,
  nome: "Edifício Jardim das Palmeiras",
  lancamento: "2020-10-25T00:00:00Z",
  entrega: "2023-05-05T00:00:00Z",
  construtora: "Construtora HIJ",
  publicado: true,
  status: "Reprovado",
  },
  {
  id: 92,
  nome: "Condomínio Jardim do Norte",
  lancamento: "2022-06-15T00:00:00Z",
  entrega: "2025-04-30T00:00:00Z",
  construtora: "Construtora JKL",
  publicado: true,
  status: "Aprovado",
  },
  {
  id: 93,
  nome: "Residencial Vale dos Lagos",
  lancamento: "2021-01-05T00:00:00Z",
    entrega: "2023-09-15T00:00:00Z",
    construtora: "Construtora KLM",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 94,
    nome: "Edifício Jardim do Vale",
    lancamento: "2020-08-05T00:00:00Z",
    entrega: "2023-02-25T00:00:00Z",
    construtora: "Construtora LMN",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 95,
    nome: "Condomínio Vale do Lago",
    lancamento: "2022-12-01T00:00:00Z",
    entrega: "2025-04-10T00:00:00Z",
    construtora: "Construtora NOP",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 96,
    nome: "Residencial Jardim das Águas Claras",
    lancamento: "2021-05-25T00:00:00Z",
    entrega: "2023-10-10T00:00:00Z",
    construtora: "Construtora OPQ",
    publicado: false,
    status: "Rascunho",
  },
  {
    id: 97,
    nome: "Edifício Vila do Mar",
    lancamento: "2020-06-25T00:00:00Z",
    entrega: "2023-08-30T00:00:00Z",
    construtora: "Construtora PQR",
    publicado: true,
    status: "Reprovado",
  },
  {
    id: 98,
    nome: "Condomínio Terra e Mar",
    lancamento: "2022-08-15T00:00:00Z",
    entrega: "2025-01-30T00:00:00Z",
    construtora: "Construtora STU",
    publicado: true,
    status: "Aprovado",
  },
  {
    id: 99,
    nome: "Residencial Bosque do Vale",
    lancamento: "2021-11-25T00:00:00Z",
    entrega: "2023-12-10T00:00:00Z",
    construtora: "Construtora UVW",
    publicado: false,
    status: "Rascunho",
  },
  {
  id: 100,
  nome: "Edifício Sol e Mar",
  lancamento: "2020-01-10T00:00:00Z",
  entrega: "2023-02-20T00:00:00Z",
  construtora: "Construtora WXY",
  publicado: true,
  status: "Reprovado",
  },
];

export const mockDataManagers = [
  { id: 1, surname: "Silva", nomeCompleto: "Carlos Silva", telefone: "(11) 91234-5678", email: "carlos.silva@example.com", incorporadora: "Incorps Ltda" },
  { id: 2, surname: "Oliveira", nomeCompleto: "Ana Oliveira", telefone: "(21) 92345-6789", email: "ana.oliveira@example.com", incorporadora: "Construtora ABC" },
  { id: 3, surname: "Pereira", nomeCompleto: "Pedro Pereira", telefone: "(31) 93456-7890", email: "pedro.pereira@example.com", incorporadora: "Construtora DEF" },
  { id: 4, surname: "Souza", nomeCompleto: "Juliana Souza", telefone: "(41) 94567-8901", email: "juliana.souza@example.com", incorporadora: "Imóveis XYZ" },
  { id: 5, surname: "Costa", nomeCompleto: "Roberto Costa", telefone: "(51) 95678-9012", email: "roberto.costa@example.com", incorporadora: "Habitação LMN" },
  { id: 6, surname: "Melo", nomeCompleto: "Fernanda Melo", telefone: "(61) 96789-0123", email: "fernanda.melo@example.com", incorporadora: "Imóveis OPQ" },
  { id: 7, surname: "Lima", nomeCompleto: "Marcos Lima", telefone: "(71) 97890-1234", email: "marcos.lima@example.com", incorporadora: "Construtora RST" },
  { id: 8, surname: "Rodrigues", nomeCompleto: "Tatiane Rodrigues", telefone: "(81) 98901-2345", email: "tatiane.rodrigues@example.com", incorporadora: "Construtora UVW" },
  { id: 9, surname: "Almeida", nomeCompleto: "Ricardo Almeida", telefone: "(91) 99012-3456", email: "ricardo.almeida@example.com", incorporadora: "Imóveis XYZ" },
  { id: 10, surname: "Araujo", nomeCompleto: "Patrícia Araujo", telefone: "(11) 90123-4567", email: "patricia.araujo@example.com", incorporadora: "Construtora DEF" },
  { id: 11, surname: "Martins", nomeCompleto: "Tiago Martins", telefone: "(21) 91234-5678", email: "tiago.martins@example.com", incorporadora: "Construtora ABC" },
  { id: 12, surname: "Fernandes", nomeCompleto: "Camila Fernandes", telefone: "(31) 92345-6789", email: "camila.fernandes@example.com", incorporadora: "Construtora RST" },
  { id: 13, surname: "Gomes", nomeCompleto: "André Gomes", telefone: "(41) 93456-7890", email: "andre.gomes@example.com", incorporadora: "Construtora UVW" },
  { id: 14, surname: "Oliveira", nomeCompleto: "Mariana Oliveira", telefone: "(51) 94567-8901", email: "mariana.oliveira@example.com", incorporadora: "Construtora UVW" },
  { id: 15, surname: "Silva", nomeCompleto: "Lucas Silva", telefone: "(61) 95678-9012", email: "lucas.silva@example.com", incorporadora: "Construtora RST" },
  { id: 16, surname: "Pereira", nomeCompleto: "Juliana Pereira", telefone: "(71) 96789-0123", email: "juliana.pereira@example.com", incorporadora: "Construtora DEF" },
  { id: 17, surname: "Costa", nomeCompleto: "Jorge Costa", telefone: "(81) 97890-1234", email: "jorge.costa@example.com", incorporadora: "Construtora LMN" },
  { id: 18, surname: "Melo", nomeCompleto: "Tatiane Melo", telefone: "(91) 98901-2345", email: "tatiane.melo@example.com", incorporadora: "Construtora ABC" },
  { id: 19, surname: "Lima", nomeCompleto: "Eduardo Lima", telefone: "(11) 99012-3456", email: "eduardo.lima@example.com", incorporadora: "Construtora OPQ" },
  { id: 20, surname: "Rodrigues", nomeCompleto: "Larissa Rodrigues", telefone: "(21) 90123-4567", email: "larissa.rodrigues@example.com", incorporadora: "Construtora XYZ" },
  { id: 21, surname: "Almeida", nomeCompleto: "Ricardo Almeida", telefone: "(31) 91234-5678", email: "ricardo.almeida@example.com", incorporadora: "Construtora LMN" },
  { id: 22, surname: "Araujo", nomeCompleto: "Fernanda Araujo", telefone: "(41) 92345-6789", email: "fernanda.araujo@example.com", incorporadora: "Construtora OPQ" },
  { id: 23, surname: "Martins", nomeCompleto: "Gabriel Martins", telefone: "(51) 93456-7890", email: "gabriel.martins@example.com", incorporadora: "Construtora DEF" },
  { id: 24, surname: "Fernandes", nomeCompleto: "Ana Fernandes", telefone: "(61) 94567-8901", email: "ana.fernandes@example.com", incorporadora: "Construtora UVW" },
  { id: 25, surname: "Gomes", nomeCompleto: "Ricardo Gomes", telefone: "(71) 95678-9012", email: "ricardo.gomes@example.com", incorporadora: "Construtora ABC" },
  { id: 26, surname: "Oliveira", nomeCompleto: "Luana Oliveira", telefone: "(81) 96789-0123", email: "luana.oliveira@example.com", incorporadora: "Construtora OPQ" },
  { id: 27, surname: "Silva", nomeCompleto: "Renato Silva", telefone: "(91) 97890-1234", email: "renato.silva@example.com", incorporadora: "Construtora XYZ" },
  { id: 28, surname: "Pereira", nomeCompleto: "Mariana Pereira", telefone: "(11) 98901-2345", email: "mariana.pereira@example.com", incorporadora: "Construtora DEF" },
  { id: 29, surname: "Costa", nomeCompleto: "Lucas Costa", telefone: "(21) 99012-3456", email: "lucas.costa@example.com", incorporadora: "Construtora LMN" },
  { id: 30, surname: "Melo", nomeCompleto: "Roberta Melo", telefone: "(31) 90123-4567", email: "roberta.melo@example.com", incorporadora: "Construtora UVW" },
  { id: 31, surname: "Lima", nomeCompleto: "Amanda Lima", telefone: "(41) 91234-5678", email: "amanda.lima@example.com", incorporadora: "Construtora RST" },
  { id: 32, surname: "Rodrigues", nomeCompleto: "João Rodrigues", telefone: "(51) 92345-6789", email: "joao.rodrigues@example.com", incorporadora: "Construtora ABC" },
  { id: 33, surname: "Almeida", nomeCompleto: "Beatriz Almeida", telefone: "(61) 93456-7890", email: "beatriz.almeida@example.com", incorporadora: "Construtora DEF" },
  { id: 34, surname: "Araujo", nomeCompleto: "Felipe Araujo", telefone: "(71) 94567-8901", email: "felipe.araujo@example.com", incorporadora: "Construtora OPQ" },
  { id: 35, surname: "Martins", nomeCompleto: "Bianca Martins", telefone: "(81) 95678-9012", email: "bianca.martins@example.com", incorporadora: "Construtora XYZ" },
  { id: 36, surname: "Fernandes", nomeCompleto: "Ricardo Fernandes", telefone: "(91) 96789-0123", email: "ricardo.fernandes@example.com", incorporadora: "Construtora LMN" },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
  
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    "hot dogColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 109,
    "hot dogColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 133,
    "hot dogColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 81,
    "hot dogColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 66,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 80,
    "hot dogColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "japan",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];