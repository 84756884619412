import React from 'react';
import { Box, Button } from '@mui/material';

const ComponentDeadline = () => {
  return (
    <Box 
    flexWrap="wrap" gap={1}s
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: "#FFFFFF", 
      borderRadius:"10px",
      //padding: "10px"
  }}
    >
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Pronto</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Até 3 Meses</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Até 6 Meses</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Até 1 Ano</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Até 2 Anos</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Até 3 Anos</Button>
    </Box>
  );
}

export default ComponentDeadline;
