import React, {useState} from 'react';

// Define o componente LocationPin
const LocationPin = ({ text, onClick }) => {
  const [hover, setHover] = useState(false);

  // Define estilos para o pino
  const pinStyle = {
    width: '40px',
    height: '40px',
    backgroundColor: '#D24234',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#05151D',
    fontSize: hover ? '35px' : '30px', 
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    transition: 'font-size 0.2s ease-in-out',
    zIndex: 1,
  };

  // Define estilos para a base do pino
  const baseStyle = {
    width: '0',
    height: '0',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderTop: '17px solid #D24234',
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
  };

  return (
    <div 
      style={{ position: 'relative', width: '40px', height: '60px' }}
      onMouseEnter={() => setHover(true)} // Define hover como true quando o mouse entra
      onMouseLeave={() => setHover(false)} // Define hover como false quando o mouse sai
      onClick={onClick}
    >
      <div style={pinStyle}>
        {text}
      </div>
      <div style={baseStyle} />
    </div>
  );
};

export default LocationPin;
