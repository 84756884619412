export const mockDataFiltro = [
  {
    id: 1,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Aurora",
    NomeConstrutora: "Construtora Alfa",
    NomeLocalizacao: "Osasco, SP",
    entrega: new Date(2024, 11, 20),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5329",
    longitude: "-46.7920",
    tipo:["casa", "apartamento"]
  },
  {
    id: 2,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Edifício Sol Nascente",
    NomeConstrutora: "Construtora Beta",
    NomeLocalizacao: "São Paulo, SP",
    entrega: new Date(2025, 5, 15),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5505",
    longitude: "-46.6333",
    tipo:["terreno"]
  },
  {
    id: 3,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Verde Vida",
    NomeConstrutora: "Construtora Gama",
    NomeLocalizacao: "Cotia, SP",
    entrega: new Date(2023, 8, 10),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.6025",
    longitude: "-46.9186",
    tipo:["apartamento"]
  },
  {
    id: 4,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Bela Vista",
    NomeConstrutora: "Construtora Delta",
    NomeLocalizacao: "Barueri, SP",
    entrega: new Date(2024, 3, 30),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5056",
    longitude: "-46.8797",
    tipo:["casa"]
  },
  {
    id: 5,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Mar Azul",
    NomeConstrutora: "Construtora Epsilon",
    NomeLocalizacao: "Santana de Parnaíba, SP",
    entrega: new Date(2024, 6, 25),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.4433",
    longitude: "-46.9172",
    tipo:["casa","terreno"]
  },
  {
    id: 6,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Bosque Verde",
    NomeConstrutora: "Construtora Zeta",
    NomeLocalizacao: "Osasco, SP",
    entrega: new Date(2024, 7, 18),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5329",
    longitude: "-46.7920",
    tipo:["apartamento"]
  },
  {
    id: 7,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Edifício Horizonte",
    NomeConstrutora: "Construtora Ômega",
    NomeLocalizacao: "São Paulo, SP",
    entrega: new Date(2025, 1, 10),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5505",
    longitude: "-46.6333",
    tipo:["casa"]
  },
  {
    id: 8,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Tranquilidade",
    NomeConstrutora: "Construtora Sigma",
    NomeLocalizacao: "Cotia, SP",
    entrega: new Date(2023, 4, 5),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.6025",
    longitude: "-46.9186",
    tipo:["casa"]
  },
  {
    id: 9,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Vista Alegre",
    NomeConstrutora: "Construtora Teta",
    NomeLocalizacao: "Barueri, SP",
    entrega: new Date(2024, 12, 15),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5056",
    longitude: "-46.8797",
    tipo:["apartamento"]
  },
  {
    id: 10,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Estrela do Mar",
    NomeConstrutora: "Construtora Psi",
    NomeLocalizacao: "Santana de Parnaíba, SP",
    entrega: new Date(2024, 9, 30),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.4433",
    longitude: "-46.9172",
    tipo:["terreno"]
  },
  {
    id: 11,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Jardim das Flores",
    NomeConstrutora: "Construtora Iota",
    NomeLocalizacao: "Osasco, SP",
    entrega: new Date(2024, 8, 12),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5329",
    longitude: "-46.7920",
    tipo:["terreno"]
  },
  {
    id: 12,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Edifício Primavera",
    NomeConstrutora: "Construtora Kappa",
    NomeLocalizacao: "São Paulo, SP",
    entrega: new Date(2025, 2, 20),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5505",
    longitude: "-46.6333",
    tipo:["casa"]
  },
  {
    id: 13,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Terra Nova",
    NomeConstrutora: "Construtora Lambda",
    NomeLocalizacao: "Cotia, SP",
    entrega: new Date(2023, 5, 10),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.6025",
    longitude: "-46.9186",
    tipo:["apartamento"]
  },
  {
    id: 14,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Bela Aurora",
    NomeConstrutora: "Construtora Mi",
    NomeLocalizacao: "Barueri, SP",
    entrega: new Date(2024, 11, 10),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5056",
    longitude: "-46.8797",
    tipo:["terreno"]
  },
  {
    id: 15,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Brisas do Mar",
    NomeConstrutora: "Construtora Nu",
    NomeLocalizacao: "Santana de Parnaíba, SP",
    entrega: new Date(2024, 10, 15),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.4433",
    longitude: "-46.9172",
    tipo:["terreno"]
  },
  {
    id: 16,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Ouro Verde",
    NomeConstrutora: "Construtora Xi",
    NomeLocalizacao: "Osasco, SP",
    entrega: new Date(2024, 7, 22),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5329",
    longitude: "-46.7920",
    tipo:["apartamento"]
  },
  {
    id: 17,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Edifício Alvorada",
    NomeConstrutora: "Construtora Omicron",
    NomeLocalizacao: "São Paulo, SP",
    entrega: new Date(2025, 3, 25),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5505",
    longitude: "-46.6333",
    tipo:["casa"]
  },
  {
    id: 18,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Vale Encantado",
    NomeConstrutora: "Construtora Pi",
    NomeLocalizacao: "Cotia, SP",
    entrega: new Date(2023, 6, 30),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.6025",
    longitude: "-46.9186",
    tipo:["apartamento","casa"]
  },
  {
    id: 19,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Brisa do Sul",
    NomeConstrutora: "Construtora Rho",
    NomeLocalizacao: "Barueri, SP",
    entrega: new Date(2024, 2, 14),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5056",
    longitude: "-46.8797",
    tipo:["casa", "apartamento"]
  },
  {
    id: 20,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Condomínio Raio de Sol",
    NomeConstrutora: "Construtora Sigma",
    NomeLocalizacao: "Santana de Parnaíba, SP",
    entrega: new Date(2024, 5, 5),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.4433",
    longitude: "-46.9172",
    tipo:["apartamento"]
  },
  {
    id: 21,
    LinkImage: "https://i.ytimg.com/vi/qiSX1eVE02g/maxresdefault.jpg",
    NomeCondominio: "Residencial Bela Floresta",
    NomeConstrutora: "Construtora Tau",
    NomeLocalizacao: "Osasco, SP",
    entrega: new Date(2024, 4, 30),
    metragens: [60, 75, 125, 200],
    valores: [500000, 550000, 960000, 1100000],
    vagas: [1, 2, 3],
    latitude: "-23.5329",
    longitude: "-46.7920",
    tipo:["apartamento"]
  }
];

export default mockDataFiltro;