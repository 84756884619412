import React, { useState } from "react";
import { Grid } from '@mui/material';

import ComponentPrice from "./ComponentPrice";
import ComponentFootage from "./ComponentFootage";
import ComponentType from "./ComponentType";
import ComponentBedRoom from "./ComponentBedRoom";
import ComponentGarage from "./ComponentGarage";
import ComponentDeadline from "./ComponentDeadline";
import ComponentLocation from "./ComponentLocation";
import ComponentTopbar from "./ComponentTopbar";

const ComponentButtonFilter = () => { 
  const [filterComponent, setFilterComponent] = useState(null);

  const renderFilterComponent = () => {
    switch (filterComponent) {
      case "Tipo":
        return <ComponentType />;
      case "Preço":
        return <ComponentPrice />;
      case "Metragem":
        return <ComponentFootage />;
      case "Quartos":
        return <ComponentBedRoom />;
      case "Vagas":
        return <ComponentGarage />;
      case "Entrega":
        return <ComponentDeadline />;
      case "Localização":
        return <ComponentLocation />;
      default:
        return null;
    }
  };

  return (
    <Grid 
      cover
      spacing={2}
      justifyItems="center"
      justifyContent="center" // Centralizar o container Grid
      sx={{ 
        justifyItems: "center",
        color: "#000000",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        backdropFilter: "blur(10px)", 
        borderRadius: "30px", 
        padding: 2,
        margin: "30px"
      }}
    >
      <ComponentTopbar setFilterComponent={setFilterComponent} />    
      <Grid item xs={12}>
        {renderFilterComponent()}
      </Grid>
    </Grid>
  );
};

export default ComponentButtonFilter;
