import { Box, Grid } from '@mui/material';
import Card from '../../../components/Card';
import mockDataFiltro from '../../../data/mockDataFiltro';

const ComponentList = () => {
  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {mockDataFiltro.map((data, index) => (
          <Card key={index} data={data} />
        ))}
      </Grid>
    </Box>
  );
};

export default ComponentList;
