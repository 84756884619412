import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login';
import Topbar from './pages/dashboard/Topbar';
import Sidebar from './pages/dashboard/Sidebar';
import Dashboard from './pages/dashboard';
import Empreendimento from './pages/commercial/empreendimentos';
import NewEmpreendimento from './pages/commercial/empreendimentos/create';
import Managers from './pages/commercial/managers/index';
import SalesMirror from './pages/sales/salesmap/index'
import Suppliers from './pages/commercial/suppliers/index';
import Filter from './pages/sales/filter/index';
import Calculator from './pages/sales/calculator/index';
import Chat from './pages/customerServices/chat/index';
import MapComponent from './pages/sales/filter/map';
import Negotiations from './pages/sales/negotiations/index';
import Materials from './pages/sales/materials/index';
import Message from './pages/customerServices/message/index';
import Schedules from './pages/sales/schedules/index';
import Warnings from './pages/customerServices/warnings/index';
import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';

function App() {
  const [theme, colorMode] = useMode();
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <div>Loading...</div>;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar />}
          <main className="content">
            {isAuthenticated && <Topbar />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/empreendimentos" element={<Empreendimento />} />
              <Route path="/empreendimentos/create" element={<NewEmpreendimento />} />
              <Route path="/suppliers" element={<Suppliers />} />
              <Route path="/filtro" element={<Filter />} />
              <Route path="/calculadora" element={<Calculator />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/managers" element={<Managers />} />
              <Route path="/negotiations" element={<Negotiations />} />
              <Route path="/map" element={<MapComponent />} />
              <Route path="/materials" element={<Materials />} />
              <Route path="/message" element={<Message />} />
              <Route path="/salesmirror" element={<SalesMirror />} />
              <Route path="/schedules" element={<Schedules />} />
              <Route path="/warnings" element={<Warnings />} />
              
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
