import { Box, IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataManagers } from "../../../data/mockData";
import Header from "../../../components/Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";


const Managers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "surname", headerName: "Surname", flex: 1 },
    { field: "nomeCompleto", headerName: "Full Name", flex: 1 },
    { field: "telefone", headerName: "Phone Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "incorporadora", headerName: "Incorporadora", flex: 1 },
    {
      field: "edit",
      headerName: "Editar",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      renderCell: ({ row }) => (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          width="100%"
          height="100%"
          >
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            color="primary"
            onClick={() => navigate(`/edit/${row.id}`)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="1%">
      <Header title="Gerentes de Parcerias" subtitle="Contato e informações dos gerentes de parcerias" />
      <Box
        m="1% 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection 
          rows={mockDataManagers} 
          columns={columns}
          initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
          }}
          disableRowSelectionOnClick
         />
      </Box>
    </Box>
  );
};

export default Managers;
