import React from "react";
import { Box, IconButton, Button, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const ComponentTopbar = ({ setFilterComponent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box alignItems="center">
      <Box
        display="flex"
        flexWrap="wrap" // Permite quebra de linha se necessário
        justifyContent="center"
        alignItems="center"
        p={2}
        gap={2} // Espaçamento entre os elementos
        sx={{ width: '100%' }}
      >
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[100]}
          borderRadius="30px"
          p={1}
          sx={{ flex:1 }} // Flex-grow e largura mínima
        >
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Pesquisar por Empreendimento ou Construtora" />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>

        {/* ICONS */}
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap" // Permite quebra de linha se necessário
          gap={1} // Espaçamento entre os botões
        >
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Tipo")}>
            Tipo
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Preço")}>
            Preço
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Metragem")}>
            Metragem
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Quartos")}>
            Quartos
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Vagas")}>
            Vagas
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Entrega")}>
            Entrega
          </Button>
          <Button sx={{ margin: 1 }} variant="contained" onClick={() => setFilterComponent("Localização")}>
            Localização
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ComponentTopbar;
