import { Box, Typography, useTheme, Switch, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataImoveis } from "../../../data/mockData"; // Verifique o caminho
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

const Negotiations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lancamento",
      headerName: "Lançamento",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, 'dd/MM/yy');
      },
    },
    {
      field: "entrega",
      headerName: "Entrega",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, 'dd/MM/yy');
      },
    },
    {
      field: "construtora",
      headerName: "Construtora",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "publicado",
      headerName: "Publicado",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <Switch checked={row.publicado} />
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1.2,
      renderCell: ({ row: { status } }) => (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <Box height="20%"></Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="60%"
            p="5px"
            borderRadius="4px"
            sx={{
              backgroundColor:
                status === "Aprovado"
                  ? colors.greenAccent[600]
                  : status === "Rascunho"
                  ? colors.greenAccent[700]
                  : colors.redAccent[500],
              minWidth: '50%',
              maxWidth: '80%',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            {status === "Aprovado" && <ThumbUpAltOutlinedIcon fontSize="small" />}
            {status === "Reprovado" && <ThumbDownAltOutlinedIcon fontSize="small" />}
            {status === "Rascunho" && <BackHandOutlinedIcon fontSize="small" />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {status}
            </Typography>
          </Box>
          <Box height="20%"></Box>
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      renderCell: ({ row }) => (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          width="100%"
          height="100%"
          >
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            color="primary"
            onClick={() => navigate(`/edit/${row.id}`)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="3%" >
      <Header title="Negociações" subtitle="Painel de Negociações" />
      <Box
        
        m="5% 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection 
        rows={mockDataImoveis} 
        columns={columns}
        initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
        }}
        
        disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Negotiations;
