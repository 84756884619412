import React, { useEffect, useState } from 'react';
import { Map, Marker, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import LocationPin from '../../../components/LocationPin';
import Card from '../../../components/Card';
import mockData from '../../../data/mockDataFiltro';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import IconButton from '@mui/material/IconButton';

const MapComponent = () => {
  const [viewport, setViewport] = useState({
    latitude: -23.6025,
    longitude: -46.9186,
    zoom: 12,
  });

  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    // Carregando dados dos marcadores do mockDataFiltro.json
    setMarkers(mockData);

    // Função para fechar o card ao pressionar a tecla Esc
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeCard();
      }
    };

    // Adiciona o listener de evento
    window.addEventListener('keydown', handleKeyDown);

    // Remove o listener de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const closeCard = () => {
    setSelectedMarker(null);
  };

  return (
    <div style={{ position: 'relative', height: "100%", width: "80vw" }}>
      <Map
        initialViewState={viewport}
        style={{width: "100%", height: "100%"}}
        mapStyle="mapbox://styles/viniciusescobar/clz7f43ma03h401nx1dv4e1az"
        mapboxAccessToken="pk.eyJ1IjoidmluaWNpdXNlc2NvYmFyIiwiYSI6ImNsejdmanhsczAxdmkydHBwM292Z3k1Z2IifQ.3-gMnutYfoMD1TFvy-qTPQ"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
      >
        {markers.map((marker, index) => (
          <Marker 
            key={index} 
            latitude={parseFloat(marker.latitude)} 
            longitude={parseFloat(marker.longitude)}
          >
            <LocationPin 
              text="a" 
              onClick={() => setSelectedMarker(marker)} 
            />
          </Marker>
        ))}
        <NavigationControl 
          showCompass={false}
          style={{ position: 'absolute', top: 10, right: 10 }}
        />
      </Map>
      {selectedMarker && (
        <div style={styles.cardContainer}>
          <IconButton 
            sx={{
              position: 'absolute', 
              top: '40px', 
              right: '40px', 
              zIndex: 1000, 
              background: 'none', 
              border: 'none', 
              cursor: 'pointer'
            }} 
            onClick={closeCard}
          >
            <CancelOutlinedIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Card data={selectedMarker} />
        </div>
      )}
    </div>
  );
};

const styles = {
  cardContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000, // Garantir que o card apareça acima do mapa
    backgroundColor: 'transparent',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: '40px',
    right: '40px',
    zIndex: 1000,
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
};

export default MapComponent;
