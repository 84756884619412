import { Grid, Autocomplete, Chip, TextField } from '@mui/material'
import mockDataFiltro from '../../../data/mockDataFiltro'

const ComponentLocation = () => {
    return (
        <Grid container spacing={2} padding="0 10% 0 10%" justifyContent="center">
            <Grid item xs={10}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={mockDataFiltro.map((option) => option.NomeLocalizacao)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip variant="outlined" label={option} key={key} {...tagProps} />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="Escolher"
                  />
                )}
              />
            </Grid>
          </Grid>
    )

}

export default ComponentLocation;