import { Grid } from '@mui/material'
 
const SalesMirror = () => {    
    return (
        <Grid>
            Mapa de vendas
        </Grid>

    )
};
export default SalesMirror;