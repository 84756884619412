import React from "react";
import { Box, Button } from "@mui/material";

const ComponentTypeFilter = () => {
  return (
    
    <Box
    flexWrap="wrap" gap={1}s
    sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#FFFFFF", 
            borderRadius:"10px",
            //padding: "10px"
        }}
    >
        <Button variant="contained" color="primary" sx={{ margin: 1 }}>
            Qualquer
        </Button>
        <Button variant="contained" color="primary" sx={{ margin: 1 }}>
            Apartamento
        </Button>
        <Button variant="contained" color="primary" sx={{ margin: 1 }}>
            Casa
        </Button>
        <Button variant="contained" color="primary" sx={{ margin: 1 }}>
            Loteamento
        </Button>
    </Box>
  );
};

export default ComponentTypeFilter;
