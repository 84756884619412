import * as React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MapComponent from './map';
import ComponentList from './list';

export default function Exhibition() {
  const [view, setView] = React.useState('Ver Lista');

  const handleToggle = (newView) => {
    setView(newView);
  };

  const fabs = [
    {
      icon: <ChecklistOutlinedIcon />,
      label: 'Ver Lista',
      view: 'Ver Lista',
    },
    {
      icon: <MapOutlinedIcon />,
      label: 'Ver Mapa',
      view: 'Ver Mapa',
    },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '90vh',
        overflow: 'hidden', // Evita overflow vertical
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'hidden', // Evita overflow no conteúdo principal
          display: 'flex',
        }}
      >
        {view === 'Ver Lista' && <ComponentList />}
        {view === 'Ver Mapa' && <MapComponent />}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 2,
          /* 
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Efeito de vidro
          backdropFilter: 'blur(10px)', // Efeito de blur
          */
          borderRadius: '30px',
          padding: '10px 20px',
          
        }}
      >
        <ButtonGroup>
          {fabs.map((fab) => (
            <Button
              key={fab.label}
              variant={view === fab.view ? 'contained' : 'outlined'}
              onClick={() => handleToggle(fab.view)}
              startIcon={fab.icon}
            >
              {fab.label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Box>
  );
}
