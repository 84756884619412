import React from 'react';
import { Box, Button } from '@mui/material';

const ComponentGarage = () => {
  return (
    <Box 
    flexWrap="wrap" gap={1}s
    sx=
    {{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: "#FFFFFF", 
      borderRadius:"10px",
      //padding: "10px"
     }}
  >
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>Qualquer</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>1</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>2</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>3</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>4</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>5</Button>
      <Button variant="contained" color="primary" sx={{ margin: 1 }}>6+</Button>
    </Box>
  );
}

export default ComponentGarage;
