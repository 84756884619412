import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { tokens } from "../../theme";
import LoginForm from '../../../src/components/LoginForm'; 

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        color: colors.grey[100],
        backgroundColor: colors.blueAccent[900],
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box 
        sx={{
          background: 'rgba(0, 0, 0, 0.5)', 
          padding: '20px',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Login
        </Typography>
        <LoginForm />
      </Box>
    </Box>
  );
};

export default Login;
