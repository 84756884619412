import { Box, useTheme, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataSuppliers } from "../../../data/mockData";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";

const Suppliers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { 
      field: "logo", 
      headerName: "Logo", 
      flex: 1, 
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 50,
            height: 50, 
            padding: 1,
            boxSizing: 'border-box',
          }}
        >
          <img
            src={params.value}
            alt="Logo"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', 
              borderRadius: '4px',
            }}
            disableRowSelectionOnClick
          />
        </Box>
      ) 
    },
    { field: "tipo", headerName: "Tipo", flex: 1 },
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "razao_social", headerName: "Razão Social", flex: 1 },
    { field: "cnpj", headerName: "CNPJ", flex: 1 },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "edit",
      headerName: "Editar",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      renderCell: ({ row }) => (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          width="100%"
          height="100%"
          >
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            color="primary"
            onClick={() => navigate(`/edit/${row.id}`)}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="1%">
      <Header
        title="Fornecedores" subtitle="Lista de parceiros envolvidos nas construções" />
      <Box
        m="1% 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection 
          rows={mockDataSuppliers}
          columns={columns}initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Suppliers;